import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Founders I work with`}</h2>
    <p>{`I like working with founders who are taking an ambitious swing at a big problem, and want to build an organization that will exist in 100 years. I relate best to systems-minded thinkers with some form of technical or scientific background. I work best with individuals who understand success requires elevating their team to meet their full potential.`}</p>
    <p>{`I'll usually work with CEO's who are running companies with $500k+ ARR or Executive Directors of non-profits with a $1m+ annual budget. For companies with longer product cycles (deeptech, bio, etc), I look at LOIs or other forms of market validation. Usually this style of coaching is best for when the team size is between 10-100 people, though occasionally I'll work with earlier stage founders.`}</p>
    <p>{`I'm domain agnostic, I've supported founders across education, software, finance, bio, hardware, and more. I'm also geography agnostic, but skew towards San Francisco. I've done a lot of work supporting students and founders from backgrounds underrepresented in tech, and I bring that awareness to my coaching. I prefer founders who value improving people's lives over profit.`}</p>
    <p>{`I want to work with founders who have a high level of buy in to the value of coaching, and see this as a long term avenue for growth rather than a way to solve short term burning problems. I believe there are ample resources to help founders with immediate fires, but few that support founders in building resilient organizations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      